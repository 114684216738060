@import '../_variables';
@import '_header-variables';

// <editor-fold desc="SECTIONS">

.section-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .section-title {
    font-family: @roboto;
    font-weight: 600;
    margin-right: auto;
    font-size: 15px;

    &:not(.no-border) a {
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    :not(.section-title) {
      flex-basis: 100%;
    }
  }

  :not(.section-title) {
    margin-top: 0;
  }

  a {
    transform: translateY(-0.25rem);
  }
}

@media (@min-screen-md) {
  .section-header {
    h1,
    h2,
    h3,
    h4,
    h5 {
      flex-basis: auto;
    }

    > :not(:last-child):not(.section-title) {
      margin-right: 1rem;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  &.section-title {
    display: inline-block;
    padding-right: 0.5em;
    padding-bottom: 0.2em;
    border-bottom: 2px solid @color-default-5;
  }
}

// </editor-fold>


// <editor-fold desc="SEARCH-MOBILE">
.search-mobile-results {
  display: inline-block !important;
  margin-bottom: 2rem;
}

.search-mobile-products {
  display: block !important;
}

.search-mobile-input {
  text-indent: 1rem;
  border: none !important;
  border-bottom: 1px solid @color-default-text-5 !important;
  background-color: #F4F4F4 !important;
  line-height: 2rem !important;
  border-radius: 0 !important;
}

.search-dropdown, .search-suggestions {

  @media (@max-screen-sm) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.5rem;
    row-gap: 1rem;
  }

  .selected {
    cursor: pointer;
    transition: 0.2s all ease;
    background: var(--color-white);
  }

  .search-suggestion {

    @media (@max-screen-sm) {
      width: 120px !important;
    }

    &:focus {
      background: var(--color-white);
    }


  }
}

.search-price-mobile {
  .price-old {
    font-size: 0.9rem;
    text-decoration: line-through;
    color: @color-default-3;
  }

  .price {
    font-size: 1rem;
    color: @color-info-1;
  }
}

.product-list-item-title {
  @media (@max-screen-sm) {
    font-size: 0.7rem;
    font-weight: 400;
    width: 120px;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    overflow: hidden; /* "overflow" value must be different from "visible" */
  }
}

// </editor-fold>
// <editor-fold desc="Product Groups and images">
.group-products-and-image{
  .content-text-desktop, .content-text-mobile{
    font-family: @font-proxima-nova;
    h2{
      margin-top: 0;
      font-weight: 700;
      font-size: 20px;
    }
  }
  @media (pointer: coarse) {
    .hidden-touch {
      display: flex !important;
    }
    .hidden-not-touch {
      display: none !important;
    }
  }

  .featured-products-vue{

    @media (@min-screen-lg) {
      .test-ebc{
        display: grid !important;
        margin: 0 auto;
        /* float: left; */
        max-width: 300px;
      }
      .product-list-2{
          width: calc(86% + 1rem) !important;
          margin: 0 auto;
      }
    }

    .product-list{
      @media (@max-screen-md){
        li{
          max-width: 50%;
        }

      }
    }
    .product-list-item{
      a:hover{
        .product-list-item-title, .product-list-item-price{
          color: #fff;
        }
      }
      .product-list-item-title{
        position: relative;
        color: #fff;
        bottom: 3.5rem;
        text-align: left;
        padding: 0.75rem 0 0.75rem 0.5rem;
        z-index: 3;
        font-weight: 700;
        font-family: @font-proxima-nova;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.6);
        @media(@max-screen-lg){
          width: 100%;
          bottom: 5.65rem;
          padding: 0.75rem 0 2rem 0.5rem;
          min-height: 5.2rem;
        }
      }
      .product-list-item-info{
        &>div{
          float: right;
          position: absolute;
          color: #fff;
          bottom: 4.2rem;
          right: 1rem;
          z-index: 5;

          @media(@max-screen-lg) {
            bottom: 6.2rem;
            float: left;
            left: 0.5rem;
          }
          @media(@min-screen-md) and (@max-screen-lg) {
            left: 1rem;
          }
        }
        .product-list-item-price{
          span{
            color: #fff;
            z-index: 4;
          }
        }
      }
    }
    @media (@min-screen-sm) and (@max-screen-lg){
      .product-list-item
      {
        max-width: 50%;
      }
    }
  }
  @media (@max-screen-sm) {
    padding-left: 0;
    padding-right: 0;
    .content-feature-products{
      padding-left: 0;
      padding-right: 0;
      .product-list{
        li{
          padding-left: 0;
          margin: 0 auto;
        }

      }
    }
  }
}
// </editor-fold>
